<template>
  <div class="smart-meter-log">
    <div class="title">
      <app-header :title="title" :isShowBack="true"></app-header>
    </div>
    <app-list :opt="opt" @get="onGet" ref="list"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      title: "查看历史",
      opt: {
        search: [
        ],
        columns: [
          { label: "房号", key: "roomNo", width: 80 },
          { label: "房客", key: "userName" },
          { label: "抄表时间", key: "meterReadingDate" },
          { label: "更新时间", key: "updateDate" },
          { label: "抄表数据", key: "meterReading" },
          { label: "本次用量", key: "consumption" },
          { label: "累积用量", key: "totalConsumption" },
        ]
      },
    };
  },
  activated() {
    console.log("smart-meter-log activated!!");
    
    if(this.$route.query.operateChargingModel=='1'){
      this.opt.columns[5].label='本次用量(度)';
      this.opt.columns[6].label='累积用量(度)';
    }
    else{
       this.opt.columns[5].label='本次用量(吨)';
      this.opt.columns[6].label='累积用量(吨)';
    }
    this.$refs.list.onRefresh();
    //this.getOrdeData(this.$route.params.id);
  },
  methods: {
    onGet(opt) {
      let dto = {
        pageNumber: opt.skip,
        pageSize: opt.limit,
        roomId: this.$route.query.roomId,
        operateChargingModel: this.$route.query.operateChargingModel,
      };
      this.post("/landlord-service/roomWaterElectric/getWebHistoryConsumption", dto, {
        isUseResponse: true
      }).then(res => {
       for(var i=0;i<res.data.length;i++){
         if(res.data[i].userName!=res.data[i+1].userName){
             res.data[i].userName=res.data[i].userName+'(入住)';
         }
       }
        opt.cb(res.data);
      });
    },
    
  }
};
</script>
<style lang="scss">
.smart-meter-log {
  .danger {
    color: #f56c6c;
  }
  .info1 {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;
    .txt {
      margin-right: 20px;
    }
  }
  .info {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;
    background-color: #e4e7ed;

    .txt {
      margin-right: 20px;
    }
  }
}
</style>
<style lang="scss" scoped>
.app-list {
  height: 750px !important;
}
</style>
